import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface Props {
	className?: string
	chatStyle?: CSSProperties
	chatName: string
	nickname?: string
	subroom?: string
	height?: string
}

export default ({
	chatName,
	nickname,
	subroom,
	chatStyle,
	height
}: Props) => {

	const queryNickname = !!nickname ? `&&nickname=${nickname}` : ""
	const querySubroom = !!subroom ? `&&subroom=${subroom}` : ""

	return (
		<iframe
			src={`https://minnit.chat/${chatName}?embed${queryNickname}${querySubroom}`}
			style={{
				border: 'none',
				width: '100%',
				height: height ? height : '100%',
				...chatStyle
			}}
		>
		</iframe>
	)
}