import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

interface ChatSideBarProps {
	children?: ReactNode
	className?: string,
}
export default ({
	children,
	className,
}: ChatSideBarProps) => {

	const [height, setHeight] = useState(0)
	useEffect(() => {
		const onResize = () => {
			setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
		};
		onResize()
		// window.addEventListener('resize', onResize);
		return () => {
			// window.removeEventListener('resize', onResize);
		};
	}, []);

	return (
		<Container className={className} style={{ height: `${height - 40}px` }}>
			{height !== 0 && children}
		</Container>
	)
}

const Container = styled.div`
	padding-top: 90px;
	background:  #5166E7;
	/* 
	
	/* display: flex;
	flex-direction: */
`
// function useHeight() {
//   const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));

//   useEffect(() => {
//     const onResize = () => {
//       setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
//     };
//     onResize()
//     // window.addEventListener('resize', onResize);
//     return () => {
//       // window.removeEventListener('resize', onResize);
//     };
//   }, []);

//   // Mobile navbar bottom = 50, 
//   return (height - 90) + 'px';
// }

// const height = useHeight()
// console.log(height)