import { useEffect, useState } from "react";

export default function useChat() {
	const [chatNickname, setChatNickname] = useState({ 
		nickname: "", 
		fetching: true 
	})

	useEffect(() => {
		const nickname = localStorage.getItem("loggedIn");
		setChatNickname({
			nickname,
			fetching: false
		})
	}, [])

	return {
		chatNickname,
	}
}