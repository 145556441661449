import React, { useState, useEffect } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO, { MetaOG } from "../components/Seo"
import { graphql } from "gatsby"
import { TransitionStatus } from "react-transition-group/Transition"
import styled from 'styled-components'

import BannerImage from "../images/haustradstefnaSocial.png"
import { StoryItemProps } from "../components/StoryRooms/StoryItem";
const LazyApp = React.lazy(() => import('../components/App/App'));


import {
  ChatEmbed,
  ChatSideBar,
  useChat,
  CHAT_NAME,
  SubroomNameEnum
} from '../components/Chat'

import ChatMobile from '../components/Chat/ChatMobile'

interface StoryRoomProps {
  data: any,
  location: Location,
  transitionStatus: TransitionStatus,
}

export default ({ data, location, transitionStatus }: StoryRoomProps) => {

  const seo_og: MetaOG = {
    title: "Þriðja hæð",
    description: "",
  }

  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    var resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  })

  const firstImageLayout: StoryItemProps = {
    xPos: "20%",
    yPos: "10%",
    heightPercentage: "55%",
    widthPercentage: "20%"
  }

  const secondImageLayout: StoryItemProps = {
    xPos: "45%",
    yPos: "0%",
    heightPercentage: "90%",
    widthPercentage: "40%"
  }

  const {
    chatNickname,
  } = useChat()

  const isSSR = typeof window === "undefined"

  if (!showMobile) {
    return (
      <Layout
        myLocation={MyLocationEnum.ThridjaHaed}
        location={location}
        transitionStatus={transitionStatus}
        showMessage={true}
      >
        {({ infoOpen, messageOpen, setMessageOpen }) => (
          <>
            <SEO
              title="Þriðja hæð"
              description=""
              og={seo_og}
            />

            <ChatSideBar onClose={() => setMessageOpen(false)} show={messageOpen}>
              {chatNickname.fetching === false && (
                <ChatEmbed
                  chatName={CHAT_NAME}
                  nickname={chatNickname.nickname}
                  subroom={SubroomNameEnum.Thridjahaedogcrossindustry}
                />
              )}
            </ChatSideBar>

            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <LazyApp
                  data={data}
                  transitionStatus={transitionStatus}
                  firstImageLayout={firstImageLayout}
                  secondImageLayout={secondImageLayout}
                />
              </React.Suspense>
            )}
          </>
        )}
      </Layout>
    )
  }
  // Mobile view.
  else {
    return (
      <LayoutMobile
        myLocation={MyLocationEnum.ThridjaHaed}
        location={location}
        transitionStatus={transitionStatus}
      >

        {chatNickname.fetching === false && (
          <ChatMobile>
            <ChatEmbed
              chatName={CHAT_NAME}
              nickname={chatNickname.nickname}
              subroom={SubroomNameEnum.Thridjahaedogcrossindustry}
            />
          </ChatMobile>
        )}

      </LayoutMobile>
    )
  }
}

const LayoutMobile = styled(Layout)`
  background: #5166E7;
`

export const query = graphql`
  query {
    ceiling: file(relativePath: { eq: "Loft-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floor: file(relativePath: { eq: "squoosh_roomFloor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concreteWall: file(relativePath: { eq: "batman-hellir-texture.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allRoom(filter: {roomName: {eq: "thridjahaedogcrossindustry"}}) {
      edges {
        node {
          firstImageNode {
            publicURL
          }
          secondImageNode {
            publicURL
          }
          thirdImageNode {
            publicURL
          }
        }
      }
    }
  } 
`