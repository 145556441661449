import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BaseButton, IconContainer } from "../Header/components/Button"
import CloseSVG from '../Svg/Close'

interface ChatSideBarProps {
	show?: boolean
	children?: ReactNode
	className?: string,
	onClose: () => void
}
export default ({
	show,
	children,
	className,
	onClose
}: ChatSideBarProps) => {

	const finalClassName = `${className} ${show && "show"}`
	return (
		<SideBar className={finalClassName}>
			<Header>
				<HeaderText>
					Velkominn í spjallið!
				</HeaderText>

				<StyledButton 
					onClick={onClose}
				>
					<IconContainer>
						<CloseSVG fill="white" width="25px" />
					</IconContainer>
				</StyledButton>
			</Header>

			{children}

		</SideBar>
	)
}

const HeaderText = styled.div`
	font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
	color: ${({theme}) => theme.colors.white};
	font-size: 24px;
	font-weight: bold;
	flex: 1;
`

const StyledButton = styled(BaseButton)`
	box-shadow: none;
	background: transparent;

	svg {
		background: transparent;
	}
`

const SideBar = styled.div`
	padding-bottom: 30px;
	padding-top: 105px;
	z-index: 0;
	height: 100vh;
	width: 34vw;
	min-width: 420px;
	max-width: 420px;
	position: fixed;
	top: 0;
	right: 0;
	background: white;
	box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.25);

	transition: transform 0.2s ease;
	transform: translate3d(100%, 0 ,0);
	
	&.show {
		z-index: 450;
		transform: translate3d(0, 0 ,0);
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 30px;
		width: 100%;
		background-color:#5166E7;
	}
`

export const Header = styled.div`
	width: 100%;
	background-color: #5166E7;
	padding: 30px 15px;
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
`